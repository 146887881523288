import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import axios from 'axios'

Vue.use(Router)
let router = new Router({
    mode: 'history',
    routes: [{
            path: '/',
            props: true,
            component: () =>
                import ('./components/Common/appDashboard'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
            children: [{
                    path: '',
                    name: "Home",
                    props: true,
                    component: () =>
                        import ('./components/Home/home'),
                    meta: {
                        requiresAuth: false,
                        isClient: true,
                    },

                }, {
                    path: '/aboutDetails',
                    name: "aboutDetails",
                    props: true,
                    component: () =>
                        import ('./components/About/aboutDetails'),
                    meta: {
                        requiresAuth: false,
                        isClient: true,
                    },

                },
                {
                    path: '/assetRegister',
                    name: 'assetRegister',
                    props: true,
                    component: () =>
                        import ('./components/Asset/assetRegister'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/unitsRegister',
                    name: 'unitsRegister',
                    props: true,
                    component: () =>
                        import ('./components/Units/unitsRegister'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/assetList',
                    name: 'assetList',
                    props: true,
                    component: () =>
                        import ('./components/Asset/assetList'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/viewAsset',
                    name: 'viewAsset',
                    props: true,
                    component: () =>
                        import ('./components/Asset/viewAsset'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/landList',
                    name: 'landList',
                    props: true,
                    component: () =>
                        import ('./components/UpdateLand/landList'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/landUpdate',
                    name: 'landUpdate',
                    props: true,
                    component: () =>
                        import ('./components/UpdateLand/landUpdate'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/unitList',
                    name: 'unitList',
                    props: true,
                    component: () =>
                        import ('./components/UpdateUnits/unitList'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },

                {
                    path: '/unitUpdate',
                    name: 'unitUpdate',
                    props: true,
                    component: () =>
                        import ('./components/UpdateUnits/unitUpdate'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/assetListTransfer',
                    name: 'assetListTransfer',
                    props: true,
                    component: () =>
                        import ('./components/TransferAsset/assetListTransfer'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/assetTransfer',
                    name: 'assetTransfer',
                    props: true,
                    component: () =>
                        import ('./components/TransferAsset/assetTransfer'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/unitListTransfer',
                    name: 'unitListTransfer',
                    props: true,
                    component: () =>
                        import ('./components/TransferUnit/unitListTransfer'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/unitTransfer',
                    name: 'unitTransfer',
                    props: true,
                    component: () =>
                        import ('./components/TransferUnit/unitTransfer'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/unitOwnershipTransfer',
                    name: 'unitOwnershipTransfer',
                    props: true,
                    component: () =>
                        import ('./components/TransferUnitOwnership/unitOwnershipTransfer'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/unitOwnershipTransferList',
                    name: 'unitOwnershipTransferList',
                    props: true,
                    component: () =>
                        import ('./components/TransferUnitOwnership/unitOwnershipTransferList'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/blockCrawler',
                    name: 'blockCrawler',
                    props: true,
                    component: () =>
                        import ('./components/BlockCrawler/blockCrawler'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/blockChainView',
                    name: 'blockChainView',
                    props: true,
                    component: () =>
                        import ('./components/BlockCrawler/blockChainView'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/blockChainTransactionView',
                    name: 'blockChainTransactionView',
                    props: true,
                    component: () =>
                        import ('./components/BlockCrawler/blockChainTransactionView'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/greenBond',
                    name: 'greenBond',
                    props: true,
                    component: () =>
                        import ('./components/Bonds/greenBond'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
                {
                    path: '/profile',
                    name: 'profile',
                    props: true,
                    component: () =>
                        import ('./components/Profile/profile'),
                    meta: {
                        requiresAuth: true,
                        isClient: true,

                    }
                },
            ]
        },
        {
            path: '/loginForm',
            name: 'Login',
            props: true,
            component: () =>
                import ('./components/Login/loginForm'),
            meta: {
                requiresAuth: false,
                isClient: true,

            }
        },
        {
            path: '/register',
            name: 'Register',
            component: () =>
                import ('./components/Login/register'),
            meta: {
                requiresAuth: false,
                isClient: true,
            }
        },
        {
            path: '/checkEmail',
            name: 'CheckEmail',
            props: true,
            component: () =>
                import ('./components/EmailVerify/checkEmail'),
            meta: {
                requiresAuth: false,
                isClient: true,

            }
        },
        {
            path: '/verify',
            name: 'Verify',
            props: true,
            component: () =>
                import ('./components/EmailVerify/verify'),
            meta: {
                requiresAuth: false,
                isClient: true,

            }
        },
        {
            path: '/forgot',
            name: 'Forgot',
            props: true,
            component: () =>
                import ('./components/ForgotPassword/forgot'),
            meta: {
                requiresAuth: false,
                isClient: true,

            }
        },
        {
            path: '/termsAndConditions',
            name: 'termsAndConditions',
            props: true,
            component: () =>
                import ('./components/Common/termsAndConditions'),
            meta: {
                requiresAuth: false,
                isClient: true,

            }
        },
        {
            path: '*',
            name: '404PageNotFound',
            props: true,
            component: () =>
                import ('./components/Common/404'),
            meta: {
                requiresAuth: false,
                isClient: true,

            }
        },
    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
})
router.beforeEach((to, from, next) => {

    if (store.state.isLoggedIn == true || to.matched.some(route => route.meta.requiresAuth == true)) {
        axios({
                method: 'GET',
                url: '/authenticate',
                headers: {
                    'token': localStorage.getItem('token')
                }
            }).then(response => {
                if (!response.data.status) {
                    store.commit("sessionOut", true)
                    return
                }
            })
            .catch(err => {
                var msg = err;
                console.log(msg)
            });
    }
    if (to.name == 'Login' && store.state.isLoggedIn == true) {
        next({ name: 'Home' })
        return
    }

    next()
})
export default router
import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import axios from 'axios'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        isLoggedIn: !!localStorage.getItem("token"),
        userType: localStorage.getItem('userType'),
        // userType: 'Client',
        level: 0,
        userData: {},
        loginAlternate: false,
        fullPath: null,
        currentPage: null,
        // hasGuestId:false,
    },
    mutations: {
        loginUser(state, item) {
            state.isLoggedIn = item

        },
        LOGIN_ALTERNATE(state, item) {
            state.loginAlternate = item

        },

        lastPage_fullPath(state, item) {
            // console.log(state,item,"fghj")
            state.fullPath = item
        },

        logoutUser(state) {
            axios({
                method: "GET",
                url: "/user/logout",
                headers: {
                    "token": localStorage.getItem("token")
                }
            }).then(response => {
                if (response.data) {
                    state.userType = 'User'
                    state.userData = {}
                    state.coupen = {}
                    localStorage.setItem("userType", 'User');
                    state.isLoggedIn = false
                    state.cartItems = 0
                    state.wishlistItems = 0
                    localStorage.removeItem("token");
                    if (window.location.pathname != '/') {
                        router.push('/')
                    }
                }
            })
        },

        userData(state, item) {
            state.userData = item
        },
        link(state, item) {
            state.link = item
        },
        code(state, item) {
            state.code = item
        },
        changePicture(state, item) {
            state.userData.image = item
        },
        changeCoverPicture(state, item) {
            state.userData.seller.coverimage = item
        },
        userType(state, item) {
            state.userType = item
            localStorage.setItem("userType", item);
        },
        changeCurrentPage(state, item) {
            state.currentPage = item
        },
        changeBuyNGetMDeal(state, item) {
            state.buyNGetMDeal = item
        },
        sessionOut(state) {
            localStorage.removeItem("token");
            state.currentPage = null
            state.isLoggedIn = false
            router.push('/')
        },

    },

})
<template>
  <div id="appNavbar">
    <ServerError v-if="ServerError" />
    <v-app-bar dense flat color="#EBF0FF" height="80px">
      <v-layout wrap justify-center pt-6>
        <v-flex xs12 sm10 md10>
          <v-layout wrap justify-start>
            <v-flex xs12 sm6 md3 lg4 align-self-start class="hidden-sm-and-down">
              <v-layout wrap justify-start>
                <v-flex xs2 sm1 md2 lg1 align-self-start @click="sideNav = !sideNav" v-if="appLogin">
                  <v-icon style="cursor: pointer" color="#000">
                    mdi-menu
                  </v-icon>
                </v-flex>
                <v-flex xs2 sm1 md2 lg1 text-left> <router-link to="/"><v-img
                    src="./../../assets/logoeco.png" contain height="25px" width="25px"></v-img>
                  </router-link>
                </v-flex>
                <v-flex xs10 sm3 md5 lg5 text-left>
                  <router-link to="/">
                    <span style="font-family: pollerOneRegular; color: #000000">ECOLedger</span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs10 sm11 class="hidden-md-and-up" pt-4>
              <v-layout wrap justify-center>
                <v-flex xs2 sm1 text-center> <router-link to="/"><v-img src="./../../assets/logoeco.png" contain
                      height="25px" width="25px"></v-img></router-link></v-flex>
                <v-flex xs5 sm1 text-center>
                  <router-link to="/">
                    <span style="font-family: pollerOneRegular; color: #000000">ECOLedger</span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 sm1 text-right class="hidden-md-and-up">
              <v-layout wrap justify-end>
                <v-app-bar-nav-icon @click.native="sideNav2 = !sideNav2">
                  <v-icon color="#000000" size="25">mdi-account</v-icon>
                </v-app-bar-nav-icon>
              </v-layout></v-flex>
            <v-spacer></v-spacer>
            <v-layout wrap justify-end class="hidden-sm-and-down" v-if="!appLogin">
              <v-flex text-right xs6 sm6 md9 pt-2>
                <router-link to="/loginForm">
                  <span style="font-family: poppinsmedium; color: #000">Log In</span>
                </router-link>
              </v-flex>
              <v-flex text-right xs6 sm6 md3>
                <v-btn rounded color="#7CB9E8" dark to="/register">
                  <span style="font-family: poppinsmedium; text-transform: none">Register</span>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-flex xs12 sm6 md9 lg7 align-self-end class="hidden-sm-and-down">
              <v-layout wrap justify-end v-if="appLogin">
                <v-flex xs12 align-self-end class="hidden-sm-and-down">
                  <v-layout wrap justify-end>
                    <!-- <v-flex xs12 sm8 md7 lg8 text-right>
                        <v-layout wrap justify-end>
                          <v-flex xs12 sm6 md6 lg6 text-right>
                            <v-text-field
                              filled
                              rounded
                              dense
                              placeholder="Search"
                              append-icon="mdi-magnify"
                              color="black"
                            >
                            </v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md5 lg5 text-right pr-2>
                            <v-btn
                              rounded
                              dark
                              color="#7CB9E8"
                              to="/assetRegister"
                            >
                              <span style="text-transform: none"
                                >Register Asset</span
                              >
                            </v-btn>
                          </v-flex>
                          <v-flex xs12 sm6 md1 lg1 text-right>
                            <v-btn rounded dark color="#7CB9E8">
                              <span style="text-transform: none"
                                >View Asset</span
                              >
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex> -->

                    <v-flex xs12 sm6 md5 lg5 pt-2 text-center class="hidden-sm-and-down">
                      <v-layout wrap justify-end align-end>
                        <v-flex xs12 sm5 md5 lg6 xl5 text-left>
                          <router-link to="/blockCrawler">
                            <span style="
                                      font-family: poppinsmedium;
                                      text-transform: none;
                                      color:#000
                                    ">Block Crawler</span>
                          </router-link>
                        </v-flex>
                        <v-flex xs1 sm1 md1 lg1 align-self-end>
                          <v-img contain height="25px" width="25px" src="./../../assets/icons/profile.png"></v-img>
                        </v-flex>
                        <v-flex xs12 sm5 md5 lg5 text-center v-if="appUser">
                          <span style="
                                      font-family: poppinsmedium;
                                      text-transform: none;
                                    ">{{ appUser.split(" ")[0] }}</span>
                          <v-menu bottom offset-y transition="scale-transition">
                            <template v-slot:activator="{ on }">
                              <v-icon color="#000" v-on="on">mdi-menu-down</v-icon>
                            </template>

                            <v-layout wrap justify-center>
                              <v-flex xs12>
                                <v-card width="150px">
                                  <v-layout wrap justify-center py-6>
                                    <v-flex xs12 sm12 pb-2>
                                      <router-link to="/profile">
                                        <span style="
                                                    font-family: poppinsmedium;
                                                    text-transform: none;
                                                    cursor: pointer;
                                                    color: #000;
                                                  ">Profile</span>
                                      </router-link>
                                    </v-flex>
                                    <v-flex xs12 sm12>
                                      <!-- <v-btn
                                        outlined
                                        color="#7CB9E8"
                                        small
                                        @click="appLogout()"
                                      > -->
                                      <span @click="appLogout()" style="
                                                  font-family: poppinsmedium;
                                                  text-transform: none;
                                                  cursor: pointer;
                                                ">Logout</span>
                                      <!-- </v-btn> -->
                                    </v-flex>
                                  </v-layout>
                                </v-card>
                              </v-flex>
                            </v-layout>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app-bar>
    <v-navigation-drawer v-model="sideNav2" fixed temporary class="hidden-lg-and-up bg1" right>
      <v-layout wrap justify-center>
        <v-flex xs12 text-center pa-2 pl-4 align-self-center>
          <v-layout wrap justify-center v-if="appLogin">
            <v-flex xs12>
              <v-layout wrap justify- v-if="appLogin">
                <v-flex xs12>
                  <v-list-group :value="false" no-action :active-class="`white--text`">
                    <template v-slot:activator>
                      <v-list-item-title class="text-left"><span style="
                                      font-family: sedanRegular;
                                      font-size: 14px;
                                      color: #000;
                                      cursor: pointer;
                                    ">Land Assets</span></v-list-item-title>
                    </template>
                    <v-flex xs12 pt-2>
                      <div style="border-bottom: 1px solid #000"></div>
                    </v-flex>
                    <v-list-item v-for="list in land" :key="list.title" :to="list.route"
                      :active-class="`rgb(136, 182, 227)`" link dense style="padding-left: 40px;">
                      <v-list-item-title><span style="
                                      font-family: sedanRegular;
                                      font-size: 12px;
                                      color: #000;
                                      cursor: pointer;
                                    ">{{ list.name }}
                        </span></v-list-item-title>
                    </v-list-item>
                  </v-list-group>
                  <v-flex xs12 pt-2>
                    <div style="border-bottom: 1px solid #000"></div>
                  </v-flex>
                  <v-list-group :value="false" no-action :active-class="`white--text`">
                    <template v-slot:activator>
                      <v-list-item-title class="text-left"><span style="
                                      font-family: sedanRegular;
                                      font-size: 14px;
                                      color: #000;
                                      cursor: pointer;
                                    ">Units</span></v-list-item-title>
                    </template>
                    <v-flex xs12 pt-2>
                      <div style="border-bottom: 1px solid #000"></div>
                    </v-flex>
                    <v-list-item v-for="list in units" :key="list.title" :to="list.route"
                      :active-class="`rgb(136, 182, 227)`" link dense style="padding-left: 40px;">
                      <v-list-item-title><span style="
                                      font-family: sedanRegular;
                                      font-size: 12px;
                                      color: #000;
                                      cursor: pointer;
                                    ">{{ list.name }}
                        </span></v-list-item-title>
                    </v-list-item>
                  </v-list-group>
                  <v-flex xs12 pt-2>
                    <div style="border-bottom: 1px solid #000"></div>
                  </v-flex>
                  <v-list-group :value="false" no-action :active-class="`white--text`">
                    <template v-slot:activator>
                      <v-list-item-title class="text-left"><span style="
                                      font-family: sedanRegular;
                                      font-size: 14px;
                                      color: #000;
                                      cursor: pointer;
                                    ">Bonds</span></v-list-item-title>
                    </template>
                    <v-flex xs12 pt-2>
                      <div style="border-bottom: 1px solid #000"></div>
                    </v-flex>
                    <v-list-item v-for="list in bonds" :key="list.title" :to="list.route"
                      :active-class="`rgb(136, 182, 227)`" link dense style="padding-left: 40px">
                      <v-list-item-title class="text-left"><span style="
                                      font-family: sedanRegular;
                                      font-size: 12px;
                                      color: #000;
                                      cursor: pointer;
                                    ">{{ list.name }}
                        </span></v-list-item-title>
                    </v-list-item>
                  </v-list-group>
                </v-flex>
                <v-flex xs12 pt-2>
                  <div style="border-bottom: 1px solid #000"></div>
                </v-flex>
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left py-2>
                    <span style="
                                font-family: sedanRegular;
                                font-size: 14px;
                                color: #000;
                                cursor: pointer;
                              ">Corporate Sustainability Disclosures
                    </span>
                  </v-flex>
                </v-layout>
                <v-flex xs12 pt-2>
                  <div style="border-bottom: 1px solid #000"></div>
                </v-flex>
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left pa-2>
                    <span style="
                                font-family: sedanRegular;
                                font-size: 14px;
                                color: #000;
                                cursor: pointer;
                              ">Climate & Biodiversity Projects
                    </span>
                  </v-flex>
                </v-layout>
                <v-flex xs12 pt-2>
                  <div style="border-bottom: 1px solid #000"></div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center>
        <v-flex v-if="!appLogin" xs12 text-center pa-2 pl-4 align-self-center>
          <router-link to="/loginForm">
            <v-layout wrap justify-center>
              <v-flex xs12 text-left>
                <span style="
                            font-family: sedanRegular;
                            font-size: 15px;
                            color: #000;
                            letter-spacing: 2px;
                          ">Login</span>
              </v-flex>
              <v-flex xs12 pt-2>
                <v-divider color="#000"></v-divider>
              </v-flex>
            </v-layout>
          </router-link>
        </v-flex>
        <v-flex v-if="!appLogin" xs12 text-center pa-2 pl-4 align-self-center>
          <router-link to="/register">
            <v-layout wrap justify-center>
              <v-flex xs12 text-left>
                <span style="
                            font-family: sedanRegular;
                            font-size: 15px;
                            color: #000;
                            letter-spacing: 2px;
                          ">Register</span>
              </v-flex>
              <v-flex xs12 pt-2>
                <v-divider color="#000"></v-divider>
              </v-flex>
            </v-layout>
          </router-link>
        </v-flex>
        <v-flex v-if="appLogin" xs12 text-center pa-2 pl-4 align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs12 text-left>
              <router-link to="/blockCrawler">
                <span style="
                          font-family: sedanRegular;
                          font-size: 15px;
                          color: #000;
                          letter-spacing: 2px;
                        ">Block Crawler</span>
              </router-link>
            </v-flex>
          </v-layout>
          <v-flex xs12 pt-2>
            <div style="border-bottom: 1px solid #000"></div>
          </v-flex>
          <v-layout wrap justify-center>

            <v-flex xs12 text-left>
              <router-link to="/assetList" style="text-decoration: none">
                <span style="
                  font-family: sedanRegular;
                  font-size: 14px;
                  color: #000;
                  cursor: pointer;
                ">View Transactions
                </span>
              </router-link>

            </v-flex>
          </v-layout>
          <v-flex xs12 pt-2>
            <div style="border-bottom: 1px solid #000"></div>
          </v-flex>
          <v-layout wrap justify-center>
            <v-flex xs12 text-left>
              <router-link to="/profile">
                <span style="
                          font-family: sedanRegular;
                          font-size: 15px;
                          color: #000;
                          letter-spacing: 2px;
                        ">Profile</span>
              </router-link>
            </v-flex>
          </v-layout>
          <v-flex xs12 pt-2>
            <div style="border-bottom: 1px solid #000"></div>
          </v-flex>
          <v-layout wrap justify-center>
            <v-flex xs12 @click="appLogout" text-left>
              <span style="
                          font-family: sedanRegular;
                          font-size: 15px;
                          color: #000;
                          letter-spacing: 2px;
                        ">Logout</span>
            </v-flex>
          </v-layout>
          <v-flex xs12 pt-2>
            <v-divider color="#000"></v-divider>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
  </div>
</template>
<script>
// import AccountMenu from "./accountMenu";
export default {
  components: {
    // AccountMenu,
  },
  data() {
    return {
      hover: false,
      appLoading: false,
      ServerError: false,
      sideNav: false,
      sideNav2: false,
      sideNav3: false,
      user: {},
      userName: null,
      profilePic: null,
      cartItems: 0,
      disabledNav: true,
      searchKey: null,
      myName: [],
      navItems: [
        { name: "Register Asset", route: "/assetRegister", type: "link" },
        { name: "View Asset", route: "/", type: "link" },
      ],
      list: [
        {
          name: "Manage Assets",
        },
        { name: "Bonds" },
        {
          name: "Corporate Sustainability Disclosures",
        },
        {
          name: "Climate & Biodiversity Projects",
        },
        {
          name: "View Transactions",
          route: "/assetList",
          type: "link",
        },
      ],
      land: [
        {
          name: "Register Asset",
          route: "/assetRegister",
          type: "link",
        },
        {
          name: "Update Asset",
          route: "/landList",
          type: "link",
        },
        {
          name: "Transfer Asset",
          route: "/assetListTransfer",
          type: "link",
        },
      ],
      units: [
        {
          name: "Register Units",
          route: "/unitsRegister",
          type: "link",
        },
        {
          name: "Update Units",
          route: "/unitList",
          type: "link",
        },
        {
          name: "Sale/Purchase of Units",
          route: "/unitListTransfer",
          type: "link",
        },
        {
          name: "Transfer Units Asset",
          route: "/unitOwnershipTransferList",
          type: "link",
        },
      ],
      asssets: [
        {
          name: "Register Asset",
          route: "/assetRegister",
          type: "link",
        },
        {
          name: "Update Asset",
          route: "/landList",
          type: "link",
        },
        {
          name: "Transfer Asset",
          route: "/assetListTransfer",
          type: "link",
        },
        {
          name: "Register Units",
          route: "/unitsRegister",
          type: "link",
        },
        {
          name: "Update Units",
          route: "/unitList",
          type: "link",
        },
        {
          name: "Sale/Purchase of Units",
          route: "/unitListTransfer",
          type: "link",
        },
        {
          name: "Transfer Units Asset",
          route: "/unitOwnershipTransferList",
          type: "link",
        },
        {
          name: "Block Crawler",
          route: "/blockCrawler",
          type: "link",
        },
      ],
      bonds: [
        {
          name: "Green Bond",
          route: "/greenBond",
          type: "link",
        },
        {
          name: "Social Bond",
        },
        {
          name: "Sustainability Bond",
        },
        {
          name: "External Review-Green Bond",
        },
        {
          name: "External Review-Social Bond",
        },
        {
          name: "External Review-Sustainability Bond",
        },
      ],
    };
  },
  computed: {
    appLogin() {
      return this.$store.state.isLoggedIn;
    },
    appUser() {
      return this.$store.state.userData.name;
    },
  },
  watch: {
    sideNav() {
      this.$emit("stepper", {
        sideNav: this.sideNav,
      });
    },
  },
  beforeMount() {
    // if (this.appUser) this.myName = this.appUser;
    // this.myName=this.myName.split(" ")
  },
  methods: {
    appLogout() {
      this.$store.commit("logoutUser", true);
      // if (this.sideNav2 == true) {
      //   this.sideNav2 = false;
      // }
    },
  },
};
</script>

<style>
input::-webkit-input-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: poppinsregular !important;
}

input::-moz-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: poppinsregular !important;
}

.searchBox .v-input__control {
  min-height: 10px !important;
}

.searchBox .v-input__control .v-input__slot {
  padding: 0px !important;
}

.v-application--is-ltr .v-toolbar__content>.v-btn.v-btn--icon:first-child+.v-toolbar__title,
.v-application--is-ltr .v-toolbar__extension>.v-btn.v-btn--icon:first-child+.v-toolbar__title {
  padding-left: 0px !important;
}

.bg1 {
  background: linear-gradient(174.14deg, #edf1fd 8.2%, #96cdf7 76.78%);
}
</style>

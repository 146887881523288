import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import store from './store'
import VueElementLoading from 'vue-element-loading'
import ServerError from './components/Common/500'
import ImageLoader from './components/Common/imageLoader'
import PageLoader from './components/Common/pageLoader'
import DatePick from 'vue-date-pick';
Vue.component('ServerError', ServerError)
Vue.component('ImageLoader', ImageLoader)
Vue.component('PageLoader', PageLoader)
Vue.component('VueElementLoading', VueElementLoading)
Vue.component('DatePick', DatePick)


Vue.config.productionTip = false
window.axios = require('axios')

// axios.defaults.baseURL = 'http://192.168.51.153:5000';
// Vue.prototype.baseURL = "http://192.168.51.153:5000"
// Vue.prototype.mediaURL = "http://192.168.51.153:5000/wp?key="
// Vue.prototype.mediaUURL = "http://192.168.51.153:5000/file/get?key="

axios.defaults.baseURL = 'https://ecol-be.actecosys.com';
Vue.prototype.baseURL = "https://ecol-be.actecosys.com"
Vue.prototype.mediaURL = "https://ecol-be.actecosys.com/wp?key="
Vue.prototype.mediaUURL = "https://ecol-be.actecosys.com/file/get?key="

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')